import { Component, OnInit, Pipe, SecurityContext, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HeaderService } from './header.service';
import { IProfileLinks } from '../../../models/profilelink.model';
import { IProduct } from './header.model';
import { AppService } from '../../app.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { EntitlementService } from 'src/app/shared/service/entitlement.service';
import { USER_PROFILE_FILTER_ARGS, ISO_EDUCATION_FILTER_ARGS, PRIMARY_HOST_DOMAIN } from '../../shared/constants/app.constants';
import { header_resources } from 'src/app/shared/resource/resource';

declare function loadCustomerSelectionJS(): any;
declare function openCustomerSelectionModal(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  objectKeys = Object.keys;

  userProfileLinks: IProfileLinks[];  // user drop-down menu data set
  userISOProducts: IProduct[]; // megamenu data set
  allISOProducts: IProduct[];  // megamenu data set
  categoryISOProducts: Object; // megamenu data set

  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS;
  isoEducationFilterArgs = ISO_EDUCATION_FILTER_ARGS;

  errorMessage = '';
  hasAllProducts = '';
  expandAllProductsDisabled = '';
  dropdownClass = '';
  homePageApiUrl = '';
  apiUrl = '';
  redirectUrl = ''
  customerSupportUrl = '';
  isAuthenticated = true;
  hover_megamenu = 'allprod';
  customerName: string = "";
  switchProfileEnabled: boolean = false;
  lblmenu = header_resources.en.menu;
  person = header_resources.en.person;

  constructor(private headerService: HeaderService,
    private router: Router,
    private cookieService: CookieService,
    private appService: AppService,
    private oauthService: OAuthService,
    private entitlementService: EntitlementService
  ) {

    this.allISOProducts = [];
    this.categoryISOProducts = {};

    const userId: string = this.cookieService.get('userid');
    const ticket: string = this.cookieService.get('ticket');

    this.appService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          const _appConfig = this.appService.getGlobalConfig();
          // this.homePageApiUrl = _appConfig.homePageApiUrl;
          //this.redirectUrl = this.getRedirectUrl(_appConfig.redirectUrl);
          //this.customerSupportUrl = _appConfig.veriskCustomerSupport;
          // this.redirectUrl = this.getRedirectUrl(_appConfig.redirectUrl);
          this.setConfig(_appConfig);
          this.headerService.getAllProducts(this.apiUrl).subscribe(
            data => {
              // populate category products
              if (data && data['categories']) {
                data['categories'].forEach(c => {
                  this.categoryISOProducts[c['title']] = c['products'];
                });
              }
              if (data && data['products']) {
                var products = data["products"].filter(x => x.title != 'Electronic Participation Reports');
                // populate all products
                let url = "";
                products.forEach(p => {
                  url = p['url'] ? p['url'].trim() : "";
                  const item: IProduct = {
                    id: p['id'],
                    title: p['title'],
                    url: url,
                    isEligible: p['isEligible'],
                    appCtxt: ""
                  };
                  this.allISOProducts.push(item);
                });
              }

              if (ticket && userId) {
                this.headerService.getEntitledUserProfileLinks(userId, ticket, this.apiUrl).subscribe(
                  _userProfileLinks => {
                    // decorate received url for iso education and append ticket and userid to the url
                    _userProfileLinks.forEach(up => {
                      if (up.linkFor === 'ISO Education') {
                        up.url += '?ticket=' + ticket + '|' + userId;
                      }
                    });

                    this.userProfileLinks = _userProfileLinks;
                  },
                  error => this.errorMessage = <any>error
                );

                this.headerService.getUserProducts(userId, ticket, this.apiUrl).subscribe(
                  _userProducts => {
                    // populate user products
                    this.userISOProducts = _userProducts;
                    this.hover_megamenu = 'myprod';
                    _userProducts.forEach(p => {
                      // reset eligible products in all products
                      const index = this.allISOProducts.findIndex((element) => {
                        return element.title === p.title;
                      });
                      if (index > -1) {
                        this.allISOProducts[index].isEligible = true;
                        this.allISOProducts[index].appCtxt = p.appCtxt;
                      }
                      // reset eligible products in category products
                      // tslint:disable-next-line: forin
                      for (const key in this.categoryISOProducts) {
                        const i = this.categoryISOProducts[key].findIndex((element) => {
                          return element.title === p.title;
                        });
                        if (i > -1) {
                          this.categoryISOProducts[key][i].isEligible = true;
                        }
                      }
                    });
                  },
                  error => this.errorMessage = <any>error
                );
              }
            }
          );
        }
      },
      error => this.errorMessage = <any>error
    );
  }

  setConfig(_appConfig) {
    this.homePageApiUrl = _appConfig.homePageApiUrl;
    this.apiUrl = _appConfig.apiUrl;
    this.redirectUrl = this.getRedirectUrl(_appConfig.redirectUrl);
    this.customerSupportUrl = _appConfig.veriskCustomerSupport;
    if (_appConfig.switchProfileEnabled == "true") {
      this.switchProfileEnabled = true;
      console.log("switchProfileEnabled :" + this.switchProfileEnabled);
      loadCustomerSelectionJS();
      this.customerName = localStorage.getItem("customername");
      console.log("customername :" + this.customerName);
    }
  }

  ngOnInit(): void { }

  public login(): void {
    this.oauthService.initImplicitFlow();
    this.oauthService.tryLogin()
      .then((loginStatus) => {
        this.entitlementService.setLoginStatus(loginStatus);
      });
  }
  public signout(): void {
    this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
    this.entitlementService.setLoginStatus(false);
    // this.oauthService.logOut();
    window.location.reload();
  }

  switchProfileClick() {
    openCustomerSelectionModal();
    //loadCustomerSelectionJS();
  }

  hoverIn(category: string): void {
    // console.log('category:' + category);
    this.hover_megamenu = category;
  }

  getRedirectUrl(redirectUrl: string): string {
    const regexUrl = new RegExp(redirectUrl);
    if (regexUrl.test(window.location.href)) {
      return regexUrl.exec(window.location.href)[0];
    }
    return window.location.href;
  }
}
